/** @format */
import "./index.scss";
import { linkList } from "@/contants";
import { Dropdown } from "antd";
import type { MenuProps } from "antd";

export default () => {
  return (
    <div className="footer">
      <div></div>
      <div>
        {/* <div className="rights">{`@${new Date().getFullYear()} All Right Reserved`}</div> */}
        <div className="link">
          {linkList.map((item, index) => {
            if (Array.isArray(item.link)) {
              const items: MenuProps["items"] = item.link.map((item) => ({
                key: item.label,
                label: item.label,
                onClick: () => window.open(item.url),
              }));
              return (
                <Dropdown
                  key={item.title}
                  menu={{ items }}
                  placement="top"
                  arrow
                >
                  <div className="link_item link_telegram">{item.icon}</div>
                </Dropdown>
              );
            }
            return (
              <div
                key={item.title}
                className="link_item"
                onClick={() => {
                  if (item.link) window?.open(item.link);
                }}
              >
                {item.icon}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
