/** @format */
import "./index.scss";
import { useTranslation, Trans } from "react-i18next";
import { useLottie } from "lottie-react";
import { Button, message } from "antd";

import FillEarthAnimation from "@/animation/data.json";

import { isTestnetStart, getLanguage } from "@/utils";

// import gif from "@/assets/fill.gif";

export default () => {
  const isProd = window?.location?.host.includes("io");

  const [messageApi, contextHolder] = message.useMessage();

  const { t, i18n } = useTranslation();
  const options = {
    animationData: FillEarthAnimation,
    loop: true,
    autoplay: false,
    onLoadedImages: () => {
      handlePlay();
    },
  };

  const Lottie = useLottie(options, {
    opacity: 0.5,
    width: "inherit",
  });

  const handleDappLaunch = () => {
    // if (!isTestnetStart()) {
    //   return messageApi.info("Coming soon!");
    // }
    window.open(
      `https://dapp.filliquid.io/${getLanguage(i18n.language)}`,
      "_self"
    );
  };

  const handlePlay = () => {
    Lottie?.setSpeed(0.35);
    Lottie?.play();
  };

  return (
    <div className="fill-first">
      {/* <img className="gif-test" src={gif} alt="" /> */}
      <div className="first-container">
        <div className="gif">{Lottie.View}</div>
        <div className="text-title">
          <div
            className="title"
            data-aos="fade-up"
            data-aos-easing="ease-in-out-cubic"
          >
            <Trans i18nKey="first-screen.title">
              Stake & Borrow Filecoin
              <span className="show-color linear ml-15 block">To Earn FIG</span>
            </Trans>
          </div>
          <div className="detail-text">{t("first-screen.desc")}</div>
          <div className="trade-btn-wrapper">
            <Button
              className="trade-btn common-btn"
              type="primary"
              onClick={handleDappLaunch}
            >
              {t("launch-dapp")}
            </Button>
          </div>
        </div>
      </div>
      {contextHolder}
    </div>
  );
};
